import React, { useState, useEffect } from "react";
import { GiHamburger } from "react-icons/gi";
import AOS from "aos";
import "aos/dist/aos.css";

const Navbar = ({ handleonclick }) => {
  const [ismobile, setismobile] = useState(false);

  const togglemenu = () => {
    setismobile(!ismobile);
  };

  const handleoff = () => {
    setismobile(false);
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
    });
  }, []);

  return (
    <div
      className="w-full h-auto bg-black bg-no-repeat text-white text-xl tracking-wide font-mosntaratbold px-10 md:px-20 grid grid-cols-1 md:grid-cols-2 grid-flow-col p-5 fixed z-50"
      data-aos="fade-down"
    >
      <div>
        <h1>ShadowTrade</h1>
      </div>
      <div className="md:flex justify-center cursor-pointer gap-10 hidden">
        <button onClick={() => handleonclick("#home")}>Home</button>
        <button onClick={() => handleonclick("#contact")}>Contact Us</button>
      </div>

      {/* Hamburger Icon */}
      <div
        onClick={togglemenu}
        className="md:hidden text-2xl text-white absolute top-5 right-5 cursor-pointer"
      >
        <GiHamburger />
      </div>

      {/* Mobile Dropdown Menu */}
      {ismobile && (
        <div
          className="md:hidden block w-full absolute top-16 right-0 text-sm z-100 bg-black p-5"
          data-aos="fade-up"
        >
          <div onClick={handleoff}>
            <h1 onClick={() => handleonclick("#home")}>Home</h1>
            <h1 onClick={() => handleonclick("#contact")}>Contact Us</h1>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
