import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Features = ({ handleonclick }) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
    });
  }, []);
  return (
    <div>
      <div
        data-aos="fade-up"
        className="w-full h-auto md:p-32 grid place-items-center md:px-32"
      >
        <h1 className="md:text-5xl text-3xl text-center font-mosntaratbold">
          Welcome to ShadowTrade
        </h1>
        <p className="text-center font-monstratitalic text-gray-700 md:text-4xl text-2xl mt-5">
          Step into one of India’s largest online multi-gaming platforms! At
          ShadowTrade, we offer exclusive IDs for sports and a vast
          selection of online games, packed with exciting rewards and unbeatable
          offers. Get your online ID instantly with complete security and
          reliability.
        </p>
      </div>
      <div className="mt-10 w-full  ">
        <video
          onClick={handleonclick}
          src="https://videos.pexels.com/video-files/6665532/6665532-uhd_2560_1440_30fps.mp4"
          className="w-full h-full rounded-lg shadow-lg"
          autoPlay
          loop
          muted
        >
          Your browser does not support the video tag.
        </video>
      </div>
      <div data-aos="fade-up" className="grid place-items-center p-10 md:p-32">
        <h1 className="md:text-4xl  text-2xl text-center font-monstratitalic text-gray-800">
          Fantasy Games are online sports games like Cricket, Football, Kabaddi,
          Basketball, and more. In these games, players build their dream teams
          using virtual currency.
        </h1>
      </div>

      <div>
        <video
          onClick={handleonclick}
          src="https://videos.pexels.com/video-files/7607945/7607945-uhd_2560_1440_25fps.mp4"
          className="w-full h-full rounded-lg shadow-lg"
          autoPlay
          loop
          muted
        >
          Your browser does not support the video tag.
        </video>
      </div>
      <div data-aos="fade-up" className="grid place-items-center md:p-32 p-10">
        <h1 className="md:text-4xl text-2xl  text-center font-monstratitalic text-gray-800">
          Players from real-life sports are given virtual values, and you can
          assemble your team from them. You’re allowed a set number of
          adjustments before the game kicks off. Once satisfied with your
          fantasy lineup, enter the contest of your preference.
        </h1>
      </div>

      <div>
        <video
          onClick={handleonclick}
          src="https://dm0qx8t0i9gc9.cloudfront.net/watermarks/video/BrltVHefQjiwahyhy/videoblocks-bnh_0734_bck4ivs6i__5bbb5b82b603a6813b6915f6d81f60e7__P360.mp4"
          className="w-full h-full rounded-lg shadow-lg"
          autoPlay
          loop
          muted
        >
          Your browser does not support the video tag.
        </video>
      </div>

      <div data-aos="fade-up" className="grid place-items-center md:p-32 p-10">
        <h1 className="md:text-4xl text-2xl  text-center font-monstratitalic text-gray-800">
          Winning fantasy games requires a solid grasp of the sport. It’s all
          about staying patient and keeping your emotions in check. With the
          right skills and a calm approach, you can turn your knowledge into
          exciting rewards!
        </h1>
      </div>

      <div>
        <video
          onClick={handleonclick}
          src="https://dm0qx8t0i9gc9.cloudfront.net/watermarks/video/N2RgWs3/videoblocks-players-in-the-casino-eagerly-gamble-and-play-in-the-casino_sybstcztw__125eab0f6383f4efa3fb6b3585a75fe2__P360.mp4"
          className="w-full h-full rounded-lg shadow-lg"
          autoPlay
          loop
          muted
        >
          Your browser does not support the video tag.
        </video>
      </div>

      <div data-aos="fade-up" className="grid place-items-center md:p-32 p-10">
        <h1 className="md:text-4xl text-2xl  text-center  font-thin text-gray-800">
          Disclaimer: This website is intended for users aged 18 or older and
          complies with all applicable local laws. Per Google Ads policies, we
          do not target users from Telangana, Andhra Pradesh, Odisha, Assam,
          Sikkim, and Nagaland. Users from these regions are advised to leave
          the website immediately. Our services are provided exclusively through
          WhatsApp. We fully comply with all relevant local gambling laws.
        </h1>
        <p className="text-5xl text-center font-thin mt-10">
          {" "}
          We Only Promote Fantasy Sports. There is no real money involved
        </p>
      </div>
    </div>
  );
};

export default Features;
